<template>
  <div class="legal-services">
    <div class="container">
      <div class="banner-static-services">
        <h4>
          {{ $t('Start your project with the support of our comprehensive legal services') }}
        </h4>
        <p>
          {{ $t('Legal Desc') }}
        </p>
      </div>
    <Contact />
     <div class="how-help-you">
        <div class="title">
          {{ $t('How can we help you ?') }}
        </div>
        <p >
         {{ $t('How can we help Desc') }}
        </p>
        <div class="row mt-5">
          <div class="col-md-3 mb-3">
            <div class="item">
              <div class="title">{{ $t('Commercial law') }}</div>
              <p>
                {{ $t('Commercial law Desc') }}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <div class="title">{{ $t('Drafting contracts and regulations') }}</div>
              <p>
                {{ $t('Drafting Desc') }}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <div class="title">{{ $t('intellectual property') }}</div>
              <p>
                {{ $t('intellectual property Desc') }}
              </p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="item">
              <div class="title">قانون العمل</div>
              <p>{{ $t('Labor law Desc') }}</p>
            </div>
          </div>
        </div>
     </div>
     <div class="slider-component">
       <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <img class="w-100" :src="require(`@/assets/img/services-page/slider-legal.png`)" />
        </div>
        <div class="col-md-6">
          <swiper class="swiper"  :pagination="pagination"
        :space-between="20" 
        :speed="1000" >
          <swiper-slide >
           <h3>{{ $t('LegalServices') }}</h3>
           <h5>{{ $t('In the Kingdom of Saudi Arabia') }}</h5>
          <p>
            {{ $t('SliderLegalText')}}
          </p>
          </swiper-slide>
          <swiper-slide >
           <h3>{{ $t('LegalServices') }}</h3>
           <h5>{{ $t('In the Kingdom of Saudi Arabia') }}</h5>
          <p>
            {{ $t('SliderLegalText2')}}
          </p>
          </swiper-slide>
          <swiper-slide >
           <h3>{{ $t('LegalServices') }}</h3>
           <h5>{{ $t('In the Kingdom of Saudi Arabia') }}</h5>
          <p>
            {{ $t('SliderLegalText3')}}
          </p>
          </swiper-slide>
        </swiper>
        </div>
       </div>
      </div>
      <div class="how-help-you">
        <div class="title">
          {{ $t('What we offer you !') }}
        </div>
        <p >
          {{ $t('Etmaam Services provides a wide range of services amounting to more than 500 services that establishments need within the Kingdom of Saudi Arabia. Whether through its team or through partnership with Etmam partners, it has a team and qualified partners to complete the services with high accuracy and in a short time.') }}
        </p>
        <div class="row what-we-offer mt-5">
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-1.png" alt="">
              <div class="title">{{ $t('Drafting and reviewing contracts') }}</div>
              <p>
              {{ $t('Preparing and reviewing contracts and agreements to ensure that the client’s rights and obligations are legally and clearly protected.') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-2.png" alt="">
              <div class="title">{{ $t('Representing clients before the courts') }}</div>
              <p>
                {{ $t('Representing clients in civil and criminal cases, commercial lawsuits and other judicial disputes') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-3.png" alt="">
              <div class="title">{{ $t('Legal consultations') }}</div>
              <p>
                {{ $t('Providing advice and consultations in various legal fields such as commercial law, civil law, labor law, and more fields') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-4.png" alt="">
              <div class="title">{{ $t('Legal compliance and compliance') }}</div>
              <p>
                {{ $t('Assisting companies in understanding and complying with local and international laws and regulations related to their business activities') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-5.png" alt="">
              <div class="title">{{ $t('Arbitration and dispute settlement') }}</div>
              <p>
                {{ $t('Providing arbitration and mediation services as an alternative to litigation in the courts to settle disputes in a more cost- and time-effective manner') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="item">
              <img src="@/assets/img/services-page/what-we-offer-6.png" alt="">
              <div class="title">{{ $t('Tax and financial issues') }}</div>
              <p>
                {{ $t('Providing legal advice on tax issues and financial planning, including defending clients in tax disputes') }}
              </p>
            </div>
          </div>
        </div>
     </div>
    </div>
   
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
    Slider: defineAsyncComponent(() => import('./component/slider-static-services.vue')),
  },
  data() {
    return {
      pagination:{
			clickable: true,
      renderBullet: function (index, className) {
          return '<span class="' + className + '">0'+(index+1)+'</span>';
        },
    }
    };
  },

}
</script>